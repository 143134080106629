import { rootStyles } from "assets/css/globalCss";
import Div from "components/shared/Div";
import { Title2 } from "components/shared/TableListeView";
import { useGetBakelisteFormationInfos } from "pages/suivi-bakelistes/api/hooks/useGetBakelisteFormationInfos";
import { useGetBakelisteValidatedTasks } from "pages/suivi-bakelistes/api/hooks/useGetBakelisteValidatedTasks";
import React from "react";
import { useSelector } from "react-redux";
import { durationFormatter } from "utils/timeFormatters";

export const PortfolioPagePrint = (props, ref) => {
  // const { currentBakeliste } = useSelector((state) => state.bakelistes);
  // const {
  //   data = { data: [], formation_days1: [] },
  //   isLoading,
  //   isError,
  // } = useGetBakelisteById(currentBakeliste.id);
  const { currentBakeliste } = useSelector((state) => state.bakelistes);
  const { bakelisteFormationInfos } = useGetBakelisteFormationInfos(
    currentBakeliste.id
  );
  const { currentBakelisteValidatedTasks } = useGetBakelisteValidatedTasks(
    currentBakeliste.id
  );
  console.log({ currentBakeliste });


  return (
    <Div className="container-fluid bg-white portfolio-page-print" refe={ref}>
      <Div className="row">
        <Div className="col-2 p-0">
          {currentBakeliste.avatar_file !== null ? (
            <img
              // src={
              //   "https://via-api.bakeli.tech" +
              //   currentBakeliste.avatar_file
              // }
              src="https://images.pexels.com/photos/1499327/pexels-photo-1499327.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="bakeliste_profil"
              className="img-fluid img-thumbnail rounded-circle"
              style={{
                width: "125px",
                height: "125px",
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              src="https://images.pexels.com/photos/1499327/pexels-photo-1499327.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="bakeliste_profil"
              className="img-fluid img-thumbnail rounded-circle"
              style={{
                width: "125px",
                height: "125px",
                objectFit: "cover",
              }}
            />
          )}
        </Div>
        <Div className="col">
          <h1 className="">{`${currentBakeliste?.first_name} ${currentBakeliste?.last_name}`}</h1>
          <span
            className="badge rounded-pill"
            style={{ backgroundColor: rootStyles.color.secondary }}
          >
            {currentBakeliste?.domaine?.name}
          </span>
          <Div className="row mt-2">
            <Div className="col">
              <Div className="row mx-1 d-flex align-items-center">
                <span className="fw-bold m-0 p-0">Email</span>
                {currentBakeliste?.email}
              </Div>
              <Div className="row mx-1 d-flex align-items-center">
                <span className="fw-bold m-0 p-0">Téléphone</span>
                {currentBakeliste?.phone}
              </Div>
              <Div className="row mx-1 d-flex align-items-center">
                <span className="fw-bold m-0 p-0">Adresse</span>
                {currentBakeliste?.address ?? "Néant"}
              </Div>
              <Div className="row mx-1 d-flex align-items-center">
                <span className="fw-bold m-0 p-0">Durée formation</span>
                {durationFormatter(
                  currentBakeliste?.debut_formation,
                  currentBakeliste?.fin_formation
                )}
              </Div>
            </Div>
            <Div className="col">
              <Div className="row mx-1 d-flex align-items-center">
                <span className="p-0 fw-bold">Type de formation</span>
                {currentBakeliste?.type_formation ?? "Néant"}
              </Div>
              <Div className="row mx-1 d-flex align-items-center">
                <span className="p-0 fw-bold">Coach</span>
                {currentBakeliste?.coach_fullName ?? "Non défini"}
              </Div>
              <Div className="row mx-1 d-flex align-items-center">
                <span className="p-0 fw-bold">Coach assistant</span>
                {currentBakeliste?.coach_assistant_fullName ??
                  "Non défini"}
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="row mt-3">
        <Title2>Syllabus suivi</Title2>
        <ul className="px-5">
          {bakelisteFormationInfos?.data?.map((item, index) => (
            <li key={index}>{item?.syllabus_name}</li>
          ))}
        </ul>
      </Div>
      <Div className="row">
        <Title2>Tâches validées</Title2>
        <ul className="px-5">
          {currentBakelisteValidatedTasks?.task_validees?.map((item, index) => (
            <li key={index}>{item?.syllabus_task}</li>
          ))}
        </ul>
      </Div>
    </Div>
  );
};

export default React.forwardRef(PortfolioPagePrint);
