import React from "react";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { useState } from "react";
import { useSelector } from "react-redux";
import ButtonComp from "components/ButtonComp";
import { rootStyles } from "assets/css/globalCss";
import SelectOnChange from "components/shared/SelectOnChange";
import { useProgrammes } from "pages/partenaires/api/hooks/useProgrammes";
import ResourceDataMap from "components/ResourceDataMap";
import Checkbox from "components/shared/Checkbox";
import Flex from "components/shared/Flex";
import { useJoursFormations } from "actions/jours/queries";
import { useTypePresence } from "actions/typePresence";
// import { getDifferentValuesFromTwoArray } from "../../../../utils/getDifferentsValuesFromTwoArrays";
import { CAMPUS_VILLES, FORMATION_BAKELI_DUREE } from "../../../../constants/index";
import { useSpaces } from "pages/parametres/Spaces/useSpaces";


const FormationBakeli = ({
  page,
  setPage,
  bakelisteInfos,
  setBakelisteInfos,
  x,
  setX,
  days,
}) => {
  const { joursFormation,
    // isError, isLoading
  } = useJoursFormations()
  const { typePresence,
    // isError: isTypePresenceError, isLoading: isTypePresenceLoading
  } = useTypePresence()
  const { spaces, isLoading } = useSpaces()

  console.log({ spaces });


  // console.log({ joursFormation, isError, isLoading, typePresence, isTypePresenceError, isTypePresenceLoading });
  // const [setIsPartenariat] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [type, setTypePresence] = useState({});
  const { allDomainesList } = useSelector((state) => state.domaines)
  const {
    // register,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const { allProgrammes } = useProgrammes()

  // ! ********* gerer le type de formation
  const handleTypeFormation = (e) => {
    setBakelisteInfos({
      ...bakelisteInfos,
      type_formation: e.target.value,
      jour_fd: "",
    });
    if (e.target.value === "  ") {
      setBakelisteInfos({
        ...bakelisteInfos,
        type_formation: e.target.value,
        is_pro: 0,
        is_etudiant: 0,
        is_etudiant_a_tester: 0,
        jour_1: "lundi",
        jour_2: "mardi",
        jour_3: "mercredi",
        jour_4: "jeudi",
        jour_5: "vendredi",
        jour_fd: "lundi au vendredi",
        nbre_jours: 5,
        is_week: 1,
        is_weekend: 0,
      });
    }
  };

  // ! ********** gerer le status professionnel
  const handleStatus = (e) => {
    const status = e.target.id;
    setValue("horaire", "");
    setValue("jour_1", "");
    setValue("jour_2", "");
    setValue("jour_3", "");
    setValue("jour_4", "");
    setValue("jour_5", "");
    setValue("jour_6", "");
    setBakelisteInfos({
      ...bakelisteInfos,
      horaire: null,
      jour_1: "",
      jour_2: "",
      jour_3: "",
      jour_4: "",
      jour_5: "",
      jour_6: "",
      jour_fd: "",
    });

    if (status === "professionnel") {
      setBakelisteInfos({
        ...bakelisteInfos,
        is_pro: 1,
        is_etudiant: 0,
        is_etudiant_a_tester: 0,
        is_week: 0,
        is_weekend: 1,
        nbre_jours: 0,
        horaire: null,
        jours_presence: [6, 7]
      });
    }
    if (status === "etudiant") {
      setBakelisteInfos({
        ...bakelisteInfos,
        is_pro: 0,
        is_etudiant: 1,
        is_etudiant_a_tester: 0,
        is_week: 0,
        is_weekend: 0,
        nbre_jours: 0,
        horaire: null,
        jour_1: "",
        jour_2: "",
        jour_3: "",
        jour_4: "",
        jour_5: "",
        jour_6: "",
        jour_fd: "",
      });
      setTypePresence("");
    }
    if (status === "etudiant_a_tester") {
      setBakelisteInfos({
        ...bakelisteInfos,
        is_pro: 0,
        is_etudiant: 1,
        is_etudiant_a_tester: 1,
        is_week: 0,
        is_weekend: 0,
        nbre_jours: null,
        horaire: null,
        jour_1: "",
        jour_2: "",
        jour_3: "",
        jour_4: "",
        jour_5: "",
        jour_6: "",
        jour_fd: "",
      });
      setTypePresence("");
    }
  };

  // ! *********** type de presence
  const handleTypeDePresence = (e) => {
    const jourDeFormation = e.target.value;
    // ! ----- professionnel
    if (bakelisteInfos.is_pro) {
      setBakelisteInfos({
        ...bakelisteInfos,
        nbre_jours: 2,
        // horaire: "17h30 - 19h30",
        is_weekend: 1,
        is_week: 0,
        jours_presence: [6, 7]
      });
    }
    // ! --------- etudiant
    if (bakelisteInfos.is_etudiant || bakelisteInfos.is_etudiant_a_tester) {
      if (jourDeFormation === "trois_jours") {
        setValue("horaire", "");
        setBakelisteInfos({
          ...bakelisteInfos,
          nbre_jours: 3,
          horaire: "",
          is_weekend: 0,
          is_week: 1,
          jours_presence: []
        });
      }
      // ! infos fixent
      if (jourDeFormation === "weekend") {
        setValue("horaire", "");
        setBakelisteInfos({
          ...bakelisteInfos,
          nbre_jours: 254,
          horaire: "9h - 17h",
          is_weekend: 1,
          is_week: 0,
          jours_presence: []
        });
      }
      if (jourDeFormation === "duLundiAuVendredi") {
        setValue("horaire", "");
        setBakelisteInfos({
          ...bakelisteInfos,
          nbre_jours: 5,
          horaire: "",
          is_weekend: 0,
          is_week: 1,
          jours_presence: joursFormation.map((jour) => (jour.id !== 6 && jour.id !== 7) && jour.id).filter((jour) => jour !== false)
        });
      }
    }
    setTypePresence(jourDeFormation);
  };

  // ! ********* next page
  const nextPage = (e) => {
    e.preventDefault();
    setX(10);
    const { horaire } = getValues();

    if (!bakelisteInfos.type_formation) {
      setError("type_formation");
      setTimeout(() => {
        clearErrors("type_formation");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.domaines_id) {
      setError("domaines_id");
      setTimeout(() => {
        clearErrors("domaines_id");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.is_formation_payante) {
      setError("is_formation_payante");
      setTimeout(() => {
        clearErrors("is_formation_payante");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.paiement_inscription) {
      setError("paiement_inscription");
      setTimeout(() => {
        clearErrors("paiement_inscription");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.is_stagede_formation) {
      setError("is_stagede_formation");
      setTimeout(() => {
        clearErrors("is_stagede_formation");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.space_id) {
      setError("space_id");
      setTimeout(() => {
        clearErrors("space_id");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.ville) {
      setError("ville");
      setTimeout(() => {
        clearErrors("ville");
      }, 1500);
      return;
    }
    if (!bakelisteInfos.lieu_formation) {
      setError("lieu_formation");
      setTimeout(() => {
        clearErrors("lieu_formation");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.bakeliste_level) {
      setError("bakeliste_level");
      setTimeout(() => {
        clearErrors("bakeliste_level");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.training_duration) {
      setError("training_duration");
      setTimeout(() => {
        clearErrors("training_duration");
      }, 1500);
      return;
    }

    if (bakelisteInfos.is_etudiant || bakelisteInfos.is_etudiant_a_tester) {
      if (bakelisteInfos.nbre_jours === 5) {
        setBakelisteInfos({
          ...bakelisteInfos,
          horaire,
        });
      }
    }
    setPage(page + 1);
  };
  // ! prev page
  const prevPage = () => {
    setPage(page - 1);
    const { horaire } = getValues();

    if (bakelisteInfos.is_etudiant) {
      if (bakelisteInfos.nbre_jours === 5) {
        setBakelisteInfos({
          ...bakelisteInfos,
          horaire,
        });
      }
    }
    setX(-10);
  };

  // useEffect(() => {
  //   refetch()
  // }, [])

  console.log(bakelisteInfos.jours_presence);

  return (
    <motion.div
      initial={{ x: x }}
      transition={{ duration: 1 }}
      animate={{ x: 0 }}
      className="multi-form"
    >
      <h6>Formation à Bakeli</h6>
      {/* select ******************** */}
      <div className="multi-form--label">
        <div>
          <label style={{ fontWeight: "bold" }} htmlFor="courte">
            <input
              name="formation"
              type="radio"
              id="courte"
              value="courte"
              defaultChecked={
                bakelisteInfos.type_formation === "courte" ? "checked" : null
              }
              onChange={handleTypeFormation}
            />{" "}
            Formation courte
          </label>
        </div>
        <div className="mt-2">
          <label style={{ fontWeight: "bold" }} htmlFor="diplomante">
            <input
              name="formation"
              type="radio"
              id="diplomante"
              value="diplomante"
              defaultChecked={
                bakelisteInfos.type_formation === "diplomante" ? "checked" : null
              }
              onChange={handleTypeFormation}
            />{" "}
            Formation diplômante
          </label>
        </div>
        {errors.type_formation && (
          <span style={{ color: "red" }} role="alert">
            Type de formation obligatoire
          </span>
        )}
      </div>

      <div className="mt-4">
        <Checkbox
          checked={bakelisteInfos.partenariat} onChange={(checked) => {
            if (!checked) {
              console.log("false");
              setBakelisteInfos({ ...bakelisteInfos, programme_id: null })
            }
            // setIsPartenariat(checked)
            setBakelisteInfos({ ...bakelisteInfos, partenariat: checked ? 1 : 0 })
          }}
          label="Partenariat ?"
        />
      </div>


      {
        bakelisteInfos.partenariat ? <div className="mt-3">
          <SelectOnChange
            defaultValue={bakelisteInfos.programme_id}
            onChange={(value) => {
              setBakelisteInfos({ ...bakelisteInfos, programme_id: Number(value) })
            }}
            label="Selectionnez le programme du partenaire"
          >
            <option value="">Selectionner le programme</option>
            <ResourceDataMap
              resourceData={allProgrammes.data}
              resourceName="programme"
              resourceItem={({ programme }) => <option value={programme.id}>{programme.name}</option>}
            />
          </SelectOnChange>
        </div> : null
      }

      {/* *************** Status professionnel *********** */}
      {bakelisteInfos.type_formation === "courte" && (
        <div className="animate__animated animate__fadeIn">
          <h4 className="mt-3 mb-1 h4-multi-step">Status professionnel</h4>
          <div className="form-status">
            <label style={{ color: "#666666" }} htmlFor="professionnel">
              <input
                name="status-professionnel"
                type="radio"
                id="professionnel"
                value="professionnel"
                defaultChecked={bakelisteInfos.is_pro ? "checked" : null}
                onChange={handleStatus}
              />{" "}
              Professionnel
            </label>
            <label
              style={{ color: "#666666" }}
              className="mx-4"
              htmlFor="etudiant"
            >
              <input
                name="status-professionnel"
                type="radio"
                id="etudiant"
                value="etudiant"
                defaultChecked={bakelisteInfos.is_etudiant ? "checked" : null}
                onChange={handleStatus}
              />{" "}
              Etudiant
            </label>
            <label style={{ color: "#666666" }} htmlFor="etudiant_a_tester">
              <input
                name="status-professionnel"
                type="radio"
                id="etudiant_a_tester"
                value="etudiant_a_teste"
                defaultChecked={
                  bakelisteInfos.is_etudiant_a_tester ? "checked" : null
                }
                onChange={handleStatus}
              />{" "}
              Etudiant à tester
            </label>
            {errors.type_formation && (
              <span style={{ color: "red" }} role="alert">
                Formation obligatoire
              </span>
            )}
          </div>
        </div>
      )}

      {/* ********************* Sceances ***************************** */}

      {bakelisteInfos.type_formation === "courte" &&
        (bakelisteInfos.is_pro === 1 ? (
          <div className="animate__animated animate__fadeIn">
          <h4 className="mt-3 mb-1 h4-multi-step">Séance</h4>
          {/* <h4 className="mt-3 mb-1 h4-multi-step">Type de présence</h4> */}
          {/* <div className="row-form">
            <div className="">
              <select
                defaultValue={bakelisteInfos.horaire}
                onChange={(e) =>
                  setBakelisteInfos({
                    ...bakelisteInfos,
                    type_presence_id: e.target.value,
                  })
                }
                disabled
                className="p-2 animate__animated animate__fadeIn"
              >
                <option value=""> -- Horaire --</option>
              </select>
              {errors.horaire && (
                <span role="alert">Horaire obligatoire</span>
              )}
            </div>
          </div> */}
          <div className="form-status mt-3">
              <label
                style={{ color: "#666666" }}
              className=""
                htmlFor="weekend"
              >
                <input
                  name="jourdeformation"
                  type="radio"
                  id="weekend"
                  value="weekend"
                  defaultChecked={
                    bakelisteInfos.is_pro && bakelisteInfos.is_weekend ?
                      "checked" :
                      null
                  }
                  onChange={handleTypeDePresence}
                />{" "}
              Weekend (samedi & Dimanche 9h - 13h)
              </label>
              {errors.type_formation && (
                <span style={{ color: "red" }} role="alert">
                  Type de présence obligatoire
                </span>
              )}
            </div>
        </div>
        ) : // ********************* etudiant ou etudiant a tester ******************************
          bakelisteInfos.is_etudiant === 1 ||
            bakelisteInfos.is_etudiant_a_tester === 1 ? (
            <div className="animate__animated animate__fadeIn form-status">
              <h4 className="mt-3 mb-1 h4-multi-step">Type de présence</h4>
              <label style={{ color: "#666666" }} htmlFor="trois_jours">
                <input
                  name="jourdeformation"
                  type="radio"
                  id="trois_jours"
                  value="trois_jours"
                  defaultChecked={
                    (bakelisteInfos.is_etudiant === 1 ||
                      bakelisteInfos.is_etudiant_a_tester === 1) &&
                      bakelisteInfos.nbre_jours === 3 ?
                      "checked" :
                      null
                  }
                  onChange={handleTypeDePresence}
                />{" "}
                Semaine (3 jours)
              </label>
              <label
                style={{ color: "#666666" }}
                className="mx-sm-4"
                htmlFor="duLundiAuVendredi"
              >
                <input
                  name="jourdeformation"
                  type="radio"
                  id="duLundiAuVendredi"
                  value="duLundiAuVendredi"
                  defaultChecked={
                    (bakelisteInfos.is_etudiant === 1 ||
                      bakelisteInfos.is_etudiant_a_tester === 1) &&
                      bakelisteInfos.nbre_jours === 5 ?
                      "checked" :
                      null
                  }
                  onChange={handleTypeDePresence}
                />{" "}
                Semaine (du lundi au vendredi)
              </label>
              {errors.type_formation && (
                <span style={{ color: "red" }} role="alert">
                  Type de présence obligatoire
                </span>
              )}
            </div>
          ) : null)}

      {/* +++++++++++++++++++++ formation etudiant 3 jours +++++++++++++++++++++++ */}
      {bakelisteInfos.type_formation === "courte" &&
        (bakelisteInfos.is_etudiant === 1 ||
          bakelisteInfos.is_etudiant_a_tester === 1) &&
        bakelisteInfos.nbre_jours === 3 && (
          <>
            <div className="row-form">
              <div>
                <select
                defaultValue={bakelisteInfos.type_presence_id}
                  onChange={(e) =>
                    setBakelisteInfos({
                      ...bakelisteInfos,
                      type_presence_id: e.target.value,
                    })
                  }
                  className="p-2 animate__animated animate__fadeIn"
                >
                  <option value=""> -- Horaire --</option>
                {
                  typePresence.map((presence) => {
                    return <option value={presence.id} key={presence.id}>
                      {presence.libelle} :
                      {presence.heure_debut} - {presence.heure_fin}
                    </option>
                  })
                }
                </select>
                {errors.horaire && (
                  <span role="alert">Horaire obligatoire</span>
                )}
            </div>
          </div>
          <div className="row_form mt-4">
            <Flex gap={20} >
              {
                joursFormation.map((jour) => {
                  return <Checkbox
                    key={jour.id}
                    // on desactive le checkbox si le nbrs_jours === jours_presence.length et que le jour ne fais pas partie de jours_presence(cela permettra de modifier)
                    disabled={bakelisteInfos.jours_presence.length === bakelisteInfos.nbre_jours && !bakelisteInfos.jours_presence.includes(jour.id)}
                    checked={bakelisteInfos.jours_presence.includes(jour.id)} onChange={(checked) => {
                      if (!checked) {
                        return setBakelisteInfos({ ...bakelisteInfos, jours_presence: bakelisteInfos.jours_presence.filter((day) => day !== jour.id) })
                      }
                      setBakelisteInfos({ ...bakelisteInfos, jours_presence: [...bakelisteInfos.jours_presence, jour.id] })
                    }}
                    label={jour.name}
                  />
                })
              }
            </Flex>
          </div>

          {/* <div className="row-form">
              <div>
              <select
                  onChange={(e) =>
                    setBakelisteInfos({
                      ...bakelisteInfos,
                      jours_presence: [...bakelisteInfos.jours_presence, e.target.value],
                    })
                  }
                  className="p-2"
                >
                  <option value=""> -- Jour 2 -- </option>
                {
                  getDifferentValuesFromTwoArray(joursFormation, bakelisteInfos.jours_presence)
                    .map((day) => (
                      <option key={day.id} value={day.id}>
                        {day.name}
                      </option>
                    ))}
                </select>
                {errors.horaire && <span role="alert">Jour obligatoire</span>}
              </div>
              <div>
                <select
                className="animate__animated animate__fadeIn p-2"
                  onChange={(e) =>
                    setBakelisteInfos({
                      ...bakelisteInfos,
                      jours_presence: [...bakelisteInfos.jours_presence, e.target.value],
                    })
                  }
                >
                  <option value=""> -- Jour 3 --</option>
                {
                  getDifferentValuesFromTwoArray(joursFormation, bakelisteInfos.jours_presence)
                    .map((day) => (
                      <option key={day.id} value={day.id}>
                        {day.name}
                      </option>
                    ))}
                </select>
                {errors.horaire && <span role="alert">Jour obligatoire</span>}
              </div>
            </div> */}
          </>
        )}

      {/* +++++++++++++++++++++ formation etudiant 5 jours +++++++++++++++++++++++ */}
      {bakelisteInfos.type_formation === "courte" &&
        (bakelisteInfos.is_etudiant === 1 ||
          bakelisteInfos.is_etudiant_a_tester === 1) &&
        bakelisteInfos.nbre_jours === 5 && (
        <>
          <div className="row-form">
            <div>
              <select
                defaultValue={bakelisteInfos.type_presence_id}
                onChange={(e) =>
                  setBakelisteInfos({
                    ...bakelisteInfos,
                    type_presence_id: e.target.value,
                  })
                }
                className="p-2 animate__animated animate__fadeIn"
              >
                <option value=""> -- Horaire --</option>
                {
                  typePresence.map((presence) => {
                    return <option value={presence.id} key={presence.id}>
                      {presence.libelle} :
                      {presence.heure_debut} - {presence.heure_fin}
                    </option>
                  })
                }
              </select>
              {errors.horaire && (
                <span role="alert">Horaire obligatoire</span>
              )}
            </div>
          </div>
          <div className="row_form mt-4">
            <Flex gap={20} >
              {
                joursFormation.map((jour) => (jour.id !== 6 && jour.id !== 7) && jour).filter((jour) => jour !== false).map((jour) => {
                  return <Checkbox
                    key={jour.id}
                    // on desactive le checkbox si le nbrs_jours === jours_presence.length et que le jour ne fais pas partie de jours_presence(cela permettra de modifier)
                    disabled={true}
                    checked={true}
                    label={jour.name}
                  />
                })
              }
            </Flex>
          </div>
          </>
        )}

      {bakelisteInfos.type_formation === "diplomante" && (
        <div className="row-form ">
          {/* <select
            defaultValue={bakelisteInfos.horaire}
            onChange={(e) =>
              setBakelisteInfos({ ...bakelisteInfos, horaire: e.target.value })
            }
            className="p-2 animate__animated animate__fadeIn"
          >
            <option value=""> -- Horaire --</option>
            <option value="8h - 17h">8h - 17h</option>
            <option value="8h - 12h30">8h - 12h30</option>
            <option value="12h30 - 17h">12h30 - 17h</option>
          </select> */}
          <p>Choisir le type de presence et les jours</p>
          {errors.horaire && <span role="alert">Horaire obligatoire</span>}
        </div>
      )}

      <div className="row-form">
        <div>
          <select
            defaultValue={bakelisteInfos.domaines_id}
            className="p-2"
            onChange={(e) => {
              setBakelisteInfos({
                ...bakelisteInfos,
                domaines_id: Number(e.target.value),
              });
            }}
          >
            <option value=""> -- choisissez un domaine --</option>
            {allDomainesList.map((domaine) => (
              <option
                name={domaine?.name}
                key={domaine?.id}
                value={domaine?.id}
              >
                {domaine.name}
              </option>
            ))}
          </select>
          {errors.domaines_id && <span role="alert">Domaine obligatoire</span>}
        </div>
        <div>
          <select
            defaultValue={bakelisteInfos.is_formation_payante}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                is_formation_payante: e.target.value,
              })
            }
          >
            <option value=""> -- Formation payante --</option>
            <option value={1}>Oui</option>
            <option value={0}>Non</option>
          </select>
          {errors.is_formation_payante && (
            <span role="alert">Formation payante obligatoire</span>
          )}
        </div>
        <div>
          <select
            required
            defaultValue={bakelisteInfos.mensualite}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                mensualite: e.target.value,
              })
            }
          >
            <option value=""> -- Mensualité --</option>
            <option value={30000}>30.000</option>
            <option value={25000}>25.000</option>
            <option value={20000}>20.000</option>
            <option value={15000}>15.000</option>
          </select>
          {errors.mensualite && (
            <span role="alert">Mensualité obligatoire</span>
          )}
        </div>
      </div>
      {/* select ******************** */}
      <div className="row-form">
        <div>
          <select
            defaultValue={bakelisteInfos.paiement_inscription}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                paiement_inscription: e.target.value,
              })
            }
          >
            <option value=""> -- Paiement inscription --</option>
            <option value={1}>Oui</option>
            <option value={0}>Non</option>
          </select>
          {errors.paiement_inscription && (
            <span role="alert">Paiement inscription obligatoire</span>
          )}
        </div>
        <div>
          <select
            defaultValue={bakelisteInfos.is_stagede_formation}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                is_stagede_formation: e.target.value,
              })
            }
          >
            <option value=""> -- Stage de formation --</option>
            <option value={1}>Oui</option>
            <option value={0}>Non</option>
          </select>
          {errors.is_stagede_formation && (
            <span role="alert">Stage de formation obligatoire</span>
          )}
        </div>
        <div>
          {/* spaces, isLoading */}
          <select
            defaultValue={bakelisteInfos.space_id}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                space_id: e.target.value,
              })
            }
          >
            {
              (isLoading && spaces?.length) ? <option>Chargement en cours</option> :
                spaces.map((space) => {
                  return <option key={space.id} value={space.id}>{space.name_space}</option>
                }).concat(<option value=""> -- Choisir le space --</option>).reverse()
            }
          </select>
          {errors.space_id && (
            <span role="alert">Space obligatoire</span>
          )}
        </div>
        <div>
          <select
            defaultValue={bakelisteInfos.ville}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                ville: e.target.value,
              })
            }
          >
            {
              CAMPUS_VILLES.map((campus) => {
                return <option key={campus.id} value={campus.id}>{campus.label}</option>
              }).concat(<option value=""> -- Choisir la ville --</option>).reverse()
            }
          </select>
          {errors.ville && (
            <span role="alert">Ville obligatoire</span>
          )}
        </div>
        <div>
          <select
            defaultValue={bakelisteInfos.lieu_formation}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                lieu_formation: e.target.value,
              })
            }
          >
            <option value=""> -- Choisir un lieu --</option>
            <option value="Grand Yoff 1">Grand Yoff 1</option>
            <option value="Grand Yoff 2">Grand Yoff 2</option>
            <option value="Thiés">Thiès</option>
            <option value="Mbour">Mbour</option>
            <option value="Labé">Labé (Guinée)</option>
            <option value="Conakry">Conakry(Guinée)</option>
          </select>
          {errors.lieu_formation && (
            <span role="alert">Lieu de formation obligatoire</span>
          )}
        </div>
      </div>
      {/* select ******************** */}
      <div className="row-form">
        <div>
          <select
            defaultValue={bakelisteInfos.bakeliste_level}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                bakeliste_level: e.target.value,
              })
            }
          >
            <option value=""> -- Choisir le niveau --</option>
            <option value="débutant">Débutant</option>
            <option value="intermédiaire">Intermédiaire</option>
            <option value="avancé">Avancé</option>
          </select>
          {errors.bakeliste_level && (
            <span role="alert">Niveau du Bakeliste obligatoire</span>
          )}
        </div>

        <div>
          <select
            defaultValue={bakelisteInfos.training_duration}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                training_duration: e.target.value,
              })
            }
          >
            <option value=""> -- Choisir une durée --</option>
            {
              FORMATION_BAKELI_DUREE.map((dureeFormation) => <option key={dureeFormation.id} value={dureeFormation.id}>{dureeFormation.label}</option>)
            }
            {/* <option value="3">3 mois</option>
            <option value="6">6 mois</option>
            <option value="12">12 mois</option>
            <option value="24">24 mois</option> */}
          </select>
          {errors.training_duration && (
            <span role="alert">Durée de formation obligatoire obligatoire</span>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <ButtonComp
          type="button"
          title="prec"
          onClick={prevPage}
          bgColor={rootStyles.color.tercary}
          color={rootStyles.color.primary}
        />
        <ButtonComp
          type="button"
          title="suivant"
          onClick={nextPage}
          bgColor={rootStyles.color.secondary}
          color={rootStyles.color.primary}
        />
        {/* <button className="btn-switch btn-prev" onClick={prevPage}>
          <img src={null} alt="Prev" className="mr-3" /> prec
        </button>
        <button className="btn-switch btn-next" onClick={nextPage}>
          suivant <img className="ml-3" src={null} alt="Next" />
        </button> */}
      </div>
    </motion.div>
  );
};

export default FormationBakeli;
